@import '~bootstrap/scss/bootstrap';
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tab-p {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == tab-l {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

* {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  line-height: 150%;
  outline: none;
}

.spacer-w {
  height: 5rem;
  @include respond(phone) {
    height: 0;
  }
}

.nav-end {
  justify-content: flex-end;
}

.link {
  cursor: pointer;
  text-align: right;
}

.header {
  background-color: #fff;
  box-shadow: 2px 0px 20px rgba(71, 71, 71, 0.3);
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0rem;
  z-index: 1;
  vertical-align: middle;

  .navbar {
    background-color: #fff;

    .logo {
      color: #f28482;
      font-size: 300%;
      font-family: 'Playfair Display', serif;
      align-self: center;
      margin-left: 0.5rem;
      cursor: pointer;

      @include respond(phone) {
        font-size: 250%;
        align-self: flex-start;
        margin-left: 0;
      }
    }
  }

  .nav-button {
    outline: none;
    border: none;
  }

  Nav {
    padding: 1rem;
  }
}

.main-container {
  min-height: 20rem;
  max-width: 90rem;
  background-color: rgb(247, 244, 244);
  margin: 0rem auto 5rem auto;
  box-shadow: 2px 2px 20px rgba(71, 71, 71, 0.3);
  display: flex;
  flex-direction: column;

  @include respond(phone) {
    margin-top: 0;
  }

  .image-bar-container {
    background-image: url(./assets/IMG-8167.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
      background-size: auto;
    }

    .brand-container {
      justify-content: center;
      margin-top: 20rem;

      @include respond(phone) {
        margin-top: 10rem;
      }

      .brand {
        color: #fdece5;
        font-size: 400%;
        font-family: 'Playfair Display', serif;
        text-shadow: -0.08rem -0.08rem #ffffff;
        text-align: center;
        @include respond(phone) {
          font-size: 350%;
        }
      }
    }

    .image-bar {
      margin: 2rem auto 20rem auto;
      @include respond(phone) {
        margin-bottom: 10rem;
      }

      .button-container {
        .contact-us-button,
        .services-button {
          background-color: rgb(84, 194, 153);
          border: none;
          color: white;
          padding: 10px 20px;
          text-align: center;
          text-decoration: none;
          font-size: 16px;
          border-radius: 0.6rem;
          box-shadow: 2px 3px 10px rgba(71, 71, 71, 0.7);
          margin: 0 1rem;
          font-size: 110%;
          font-weight: 500;
          transition-duration: 0.4s;
          cursor: pointer;
          outline: none;
        }

        .contact-us-button:hover,
        .services-button:hover {
          background-color: #f6bd60;
          color: white;
        }
      }
    }
  }

  .bio {
    margin: 4rem 4rem 0 4rem;
    background-color: #fff;
    box-shadow: 1px 1px 20px rgba(71, 71, 71, 0.2);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;

    @include respond(phone) {
      padding: 1rem;
      margin: 1rem;
    }

    &__title {
      color: #f28482;
      font-size: 230%;
      font-family: 'Playfair Display', serif;
      margin-bottom: 1rem;
    }

    &__description {
      font-size: 105%;
    }
  }

  .boarding-about {
    margin: 4rem;
    background-color: #fff;
    box-shadow: 1px 1px 20px rgba(71, 71, 71, 0.2);
    border-radius: 1rem;
    padding: 2rem;

    @include respond(phone) {
      margin: 1rem;
      padding: 1rem;
      text-align: center;
    }
    .boarding-head {
      margin: 0 2rem;

      @include respond(phone) {
        margin: 0;
      }
    }

    .boarding-title {
      color: #f28482;
      font-size: 230%;
      font-family: 'Playfair Display', serif;
      margin-bottom: 1rem;
    }

    .about-text {
      font-size: 110%;
    }
  }
}

.grooming {
  margin: 4rem 4rem 0rem 4rem;
  background-color: #fff;
  box-shadow: 1px 1px 20px rgba(71, 71, 71, 0.2);
  border-radius: 1rem;

  @include respond(phone) {
    text-align: center;
    margin: 1rem;
  }

  &__head {
    margin: 2rem 3rem;

    @include respond(phone) {
      margin: 0rem;
      margin-bottom: 2rem;
    }
    &__title {
      color: #f28482;
      font-size: 230%;
      font-family: 'Playfair Display', serif;
      margin-bottom: 1rem;
    }
  }

  &__info {
    margin: 0 3rem 2rem 3rem;

    @include respond(phone) {
      margin: 1rem;
    }

    .grooming-pricing-container {
      display: flex;
      margin-bottom: 2rem;
      @include respond(phone) {
        flex-direction: column;
      }

      .the-works {
        width: 50%;
        @include respond(phone) {
          width: 100%;
          margin-bottom: 2rem;
        }

        &__title {
          color: #4f675e;
          font-size: 170%;
          font-family: 'Playfair Display', serif;
          margin-bottom: 1rem;
        }

        &__description {
          font-size: 110%;
          margin-bottom: 1rem;
          padding-right: 1rem;
          @include respond(phone) {
            padding-right: 0;
          }
        }

        &__pricing {
          font-size: 110%;
          display: flex;
          flex-direction: column;

          @include respond(phone) {
            align-items: center;
          }

          &__item {
            display: flex;
            align-items: center;

            .w-pricing-item-title {
              margin-right: 0.3rem;
              font-size: 110%;
              color: #954241;
              font-weight: 600;
            }
          }
        }
      }

      .regular-grooming {
        width: 50%;

        @include respond(phone) {
          width: 100%;
        }

        &__title {
          color: #4f675e;
          font-size: 170%;
          font-family: 'Playfair Display', serif;
          margin-bottom: 1rem;
        }

        &__detail {
          font-size: 110%;
          margin-bottom: 1rem;
        }

        &__note {
          color: rgba(71, 71, 71, 0.8);
          margin-bottom: 1rem;
        }

        &__pricing {
          font-size: 110%;
          display: flex;
          flex-direction: column;

          @include respond(phone) {
            align-items: center;
          }

          &__item {
            display: flex;
            align-items: center;

            .r-pricing-item-title {
              font-size: 110%;
              color: #954241;
              font-weight: 600;
              margin-right: 0.3rem;
            }
          }
        }
      }
    }

    .grooming-additional-container {
      display: flex;

      @include respond(phone) {
        flex-direction: column;
        margin-bottom: 2rem;
      }

      .additional-grooming {
        width: 50%;
        @include respond(phone) {
          width: 100%;
          margin-bottom: 2rem;
        }

        &__title {
          color: #4f675e;
          font-size: 170%;
          font-family: 'Playfair Display', serif;
          margin-bottom: 1rem;

          @include respond(phone) {
            margin: 0 3rem 1rem 3rem;
          }
        }

        &__item {
          font-size: 110%;
        }

        &__item::before {
          content: '';
          display: inline-block;
          height: 1rem;
          width: 1.2rem;
          margin-right: 0.2rem;
          background-color: #e2aa56;
          -webkit-mask-image: url(./assets/chevron-right.svg);
          -webkit-mask-size: cover;
        }
      }

      .g-size-guide {
        width: 50%;
        @include respond(phone) {
          width: 100%;
        }

        &__title {
          color: #4f675e;
          font-size: 170%;
          font-family: 'Playfair Display', serif;
          margin-bottom: 1rem;
        }
        .g-size-guide-container {
          display: flex;
          flex-direction: column;
          @include respond(phone) {
            align-items: center;
          }

          .g-size-guide-item {
            font-size: 110%;
            display: flex;

            .size-title {
              font-size: 110%;
              color: #954241;
              font-weight: 600;
              margin-right: 0.3rem;
            }

            .size-detail {
            }
          }
        }
      }
    }
  }

  .g-outer-image-container {
    .grooming-image-container {
      display: flex;
      margin: 2rem;
      @include respond(phone) {
        margin: 1rem 0;
      }

      .grooming-image {
        width: 100%;
        display: block;
      }
    }
  }
}

.boarding-information {
  display: flex;
  flex-direction: column;
  margin: 2rem;

  .boarding-require-columns {
    display: flex;
    @include respond(phone) {
      flex-direction: column;
    }

    .boarding-list-container {
      width: 50%;
      @include respond(phone) {
        width: 100%;
        margin-bottom: 2rem;
      }

      @include respond(phone) {
        margin: 0;
        margin-bottom: 2rem;
      }

      &__title {
        color: #4f675e;
        font-size: 170%;
        font-family: 'Playfair Display', serif;
        margin-bottom: 1rem;
      }

      &__item {
        font-size: 110%;
      }

      &__item::before {
        content: '';
        display: inline-block;
        height: 1rem;
        width: 1.2rem;
        margin-right: 0.2rem;
        background-color: #e2aa56;
        -webkit-mask-image: url(./assets/chevron-right.svg);
        -webkit-mask-size: cover;
      }
    }

    .boarding-requirement-container {
      width: 50%;
      @include respond(phone) {
        width: 100%;
        margin-bottom: 2rem;
      }
      &__title {
        color: #4f675e;
        font-size: 170%;
        font-family: 'Playfair Display', serif;
        margin-bottom: 1rem;
      }

      &__item {
        font-size: 110%;
      }

      &__item::before {
        content: '';
        display: inline-block;
        height: 1rem;
        width: 1.2rem;
        margin-right: 0.2rem;
        background-color: #e2aa56;
        -webkit-mask-image: url(./assets/chevron-right.svg);
        -webkit-mask-size: cover;
      }
    }
  }

  .boarding-times-subtitle {
    font-size: 110%;
    color: #954241;
    font-weight: 600;
    margin-right: 0.3rem;
  }

  .boarding-special-note {
    margin: 2rem 1rem;
    font-size: 110%;
  }

  .boarding-times {
    width: 50%;
    @include respond(phone) {
      width: 100%;
      margin-bottom: 2rem;
    }
    &__title {
      color: #4f675e;
      font-size: 170%;
      font-family: 'Playfair Display', serif;
      margin-bottom: 1rem;
    }

    &__item {
      vertical-align: middle;
      font-size: 110%;
      margin: auto 0;
    }
  }
}

.outer-image-container {
  .boarding-image-container {
    display: flex;
    @include respond(phone) {
      margin: 0 -1rem;
    }

    .boarding-image {
      width: 100%;
      display: block;
    }
  }
}

.chevron-right {
  vertical-align: middle;
  top: -30px;
}

.form-map {
  display: flex;
  @include respond(phone) {
    flex-direction: column;
    padding: 1rem;
  }

  .map-container {
    width: 100%;
    margin: 0rem 4rem 4rem 0rem;
    background-color: #fff;
    box-shadow: 1px 1px 20px rgba(71, 71, 71, 0.2);
    border-radius: 1rem;
    flex: 1;
    padding: 1rem;

    div {
      width: 100%;
      height: 100%;
      @include respond(phone) {
        width: 100%;
        height: 30rem;
      }
      div {
        height: 0;
      }
    }

    @include respond(phone) {
      flex-direction: column;
      width: 100%;
      height: 20rem;
    }

    // .gmap {
    //   height: 100%;
    //   width: 100%;
    //   @include respond(phone) {
    //     flex-direction: column;
    //     width: 100%;
    //     height: 30rem;
    //   }
    // }
  }
}

.button-container {
  margin-left: auto;
}

.boarding-time-pricing {
  display: flex;
  @include respond(phone) {
    flex-direction: column;
  }
}

.boarding-pricing {
  width: 50%;
  @include respond(phone) {
    width: 100%;
    margin-bottom: 2rem;
  }

  &__title {
    color: #4f675e;
    font-size: 170%;
    font-family: 'Playfair Display', serif;
    margin-bottom: 1rem;
  }

  &__short {
    font-size: 110%;
    display: flex;
    @include respond(phone) {
      justify-content: center;
    }
    &__title {
      font-size: 110%;
      color: #954241;
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }
  &__long {
    font-size: 110%;
    display: flex;
    @include respond(phone) {
      justify-content: center;
    }
    &__title {
      font-size: 110%;
      color: #954241;
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }
  &__night {
    font-size: 110%;
    display: flex;
    @include respond(phone) {
      justify-content: center;
    }
    &__title {
      font-size: 110%;
      color: #954241;
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }
}
