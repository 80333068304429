@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tab-p {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == tab-l {
    @media (max-width: 1200px) {
      @content;
    }
  }
}
.contact-form {
  margin: 0rem 2rem 4rem 4rem;
  background-color: #fff;
  box-shadow: 1px 1px 20px rgba(71, 71, 71, 0.2);
  border-radius: 1rem;
  padding: 3rem;
  flex: 1;

  @include respond(phone) {
    padding: 1.5rem;
    margin: auto;
    margin-bottom: 2rem;
  }
  .contact-form-title-email {
    .contact-form__title {
      color: #f28482;
      font-size: 250%;
      font-family: 'Playfair Display', serif;
      margin-bottom: 0.5rem;
    }
    .contact-form-support-email {
      font-size: 110%;
      margin-bottom: 1.5rem;
    }
  }
}

.form-name-email {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  @include respond(phone) {
    flex-direction: column;
  }

  .name-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2rem;

    @include respond(phone) {
      width: 100%;
      margin-bottom: 1rem;
    }

    .name {
      border: none;
      border: 1px solid rgba(107, 107, 107, 0.603);
      height: 2rem;
      font-size: 110%;
      padding: 0 0.5rem;
    }
  }

  .email-group {
    display: flex;
    flex-direction: column;
    flex: 1;

    .email {
      border: none;
      border: 1px solid rgba(107, 107, 107, 0.603);
      height: 2rem;
      font-size: 110%;
      padding: 0 0.5rem;
    }
  }
}

.message-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0rem;

  .message {
    border: none;
    border: 1px solid rgba(107, 107, 107, 0.603);

    font-size: 110%;
    padding: 0 0.5rem;

    height: 10rem;
    resize: none;
  }
}

.btn {
  background-color: rgb(84, 194, 153); /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 0.6rem;
  margin: 1rem 0;
  font-size: 110%;
  font-weight: 500;
  width: 10rem;
  cursor: pointer;
}

.btn:hover {
  background-color: #f6bd60;
}

.f-button-container {
  display: flex;
  width: 100%;

  .email-response {
    flex: 1;
    align-self: center;
    font-size: 110%;
    color: #954241;
    font-weight: 600;
  }
}

.phone {
  visibility: hidden;
  height: 0;
}
